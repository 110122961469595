import { useTranslations } from 'next-intl';
import { StoreLocatorPlaceHolderBanner } from '../store-locator-placeholder-banner/store-locator-placeholder-banner';
import { TopPanel } from '../top-panel/top-panel';
import s from './store-locator-v2.module.scss';
import cn from 'classnames';
import { MerchantKeywordFilter } from '../merchant-keyword-filter';
import { CategoryView } from '../category-view';
import { Switches } from '../switches';
import { useEffect, useState } from 'react';
import { MerchantCardHoverProvider } from '@ee-monorepo/store-locator/shared/ui';
import { useSelector } from 'react-redux';
import {
  selectErrorLoadingMerchants,
  selectHasResults,
  selectMerchant,
  selectResultsCity,
  selectResultsState,
  useStoreLocatorRouterActions,
} from '@ee-monorepo/store-locator/shared/data-access';
import { LocationBreadCrumb } from '../location-bread-crumb';
import { LocationSwitch } from '../location-switch';
import { useTablet } from '@ee-monorepo/shared/utilities/hooks';
import { useStoreLocatorParamsV2 } from '../../../hooks/useStoreLocatorParamsV2';
import { InStoreMerchantsResults } from '../../lazy/in-store-merchants-results';
import { MerchantsResultsFilters } from '../merchants-results-filters';
import { MerchantDetailContainer } from '../../lazy/merchant-detail-container';
import { useUserLocation } from '../../../hooks/useUserLocation';
import { useRouter } from 'next/router';

export function StoreLocatorV2() {
  const router = useRouter();
  const city = useSelector(selectResultsCity);
  const state = useSelector(selectResultsState);
  const hasResults = useSelector(selectHasResults);
  const merchant = useSelector(selectMerchant);
  const errorLoadingMerchants = useSelector(selectErrorLoadingMerchants);
  const t = useTranslations();
  const [selectedLocationOnHover, setSelectedLocationOnHover] = useState(null);
  const isMobile = useTablet();
  const { hasLocationParams, hasLatLong, clear } = useStoreLocatorParamsV2();
  const [isPushingGeoLocation, setIsPushingGeoLocation] = useState(true);

  //Check for user location automatically
  const { pushGeoCoordinates } = useStoreLocatorRouterActions();

  const onGeoCoordsCallback = async ({
    latitude,
    longitude,
  }: GeolocationCoordinates) => {
    if (!hasLocationParams && isPushingGeoLocation) {
      pushGeoCoordinates({ latitude, longitude });
    }
  };

  useUserLocation({ onGeoCoordsCallback });

  useEffect(() => {
    if (hasLatLong && errorLoadingMerchants) {
      setIsPushingGeoLocation(false);
      router.push('/find-stores');
      clear();
    }
  }, [hasLatLong, errorLoadingMerchants]);

  return (
    <div data-testid="store-locator-v2" className="lg:h-[110vh]">
      {merchant ? (
        <div
          className={`fixed right-0 top-0 h-full lg:top-auto bg-SnapWhite w-full lg:w-1/4 transition-transform transform border-1 ${
            merchant
              ? 'translate-x-0 transition-all duration-1000'
              : 'translate-x-full transition-all duration-1000'
          } z-50`}
        >
          <MerchantDetailContainer
            className={cn(
              s.border,
              s.resultsPanel,
              s.maxResultContent,
              !isMobile && s.stickyContent
            )}
          />
        </div>
      ) : null}
      {!isMobile || (isMobile && !merchant) ? (
        <div
          className={cn(s.contentHeight, 'flex flex-col', {
            'opacity-30 pointer-events-none': merchant,
          })}
        >
          {(isMobile && !hasResults) || !isMobile ? (
            <TopPanel
              isLocationAvailable={hasLocationParams}
              city={city}
              state={state}
            ></TopPanel>
          ) : null}
          {!hasLocationParams ? (
            <div className="h-full">
              <StoreLocatorPlaceHolderBanner
                imageSrc={`assets\\icons\\store-locator\\dog.svg`}
                imageHeight={130}
                imageWidth={130}
                imageAlt={`Dog`}
                showMerchantLocationInput={true}
                showActionButton={false}
                showBoldText={false}
                primaryTextString={t(
                  `shared.store_locator_new_ui_location_unavailable.label`
                )}
                dataTestId="zip-code-banner"
              />
            </div>
          ) : (
            <div
              data-testid="store-locator-content"
              className="flex h-full flex-col lg:flex-row mt-[24px] px-4 lg:px-0 lg:snap-container "
            >
              {!isMobile || (isMobile && !hasResults) ? (
                <div
                  data-testid="store-locator-filters"
                  className={cn(
                    'lg:mr-[16px] lg:min-w-[300px] lg:w-[300px]',
                    'flex flex-col gap-2.5'
                  )}
                >
                  <Switches activeIndex={0} className="!overflow-visible" />
                  <MerchantKeywordFilter />
                  <div className="py-5">
                    <div
                      id="horizontal-line"
                      className="relative w-full h-[1px] bg-SnapGrey300"
                    >
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center w-10 h-10 border-2 bg-SnapDarkBlue border-SnapDarkBlue rounded-full">
                        <span className={cn('tagLargeFont', 'text-white')}>
                          {t(`shared.uppercase_or`)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <CategoryView />
                </div>
              ) : null}
              <div className="w-px bg-SnapGrey300"></div>
              <div
                data-testid="store-locator-results"
                className="w-full lg:pl-[16px]"
              >
                {hasResults && !errorLoadingMerchants ? (
                  <div>
                    {isMobile ? (
                      <div data-testid="store-locator-m-filters">
                        <Switches activeIndex={0} className="mt-2.5" />
                        <MerchantsResultsFilters />
                      </div>
                    ) : null}
                    <LocationSwitch isNewStoreLocator={true} />
                    <MerchantCardHoverProvider>
                      <InStoreMerchantsResults
                        className={cn(
                          s.findStoresScrollable,
                          s.maxContentMerchant,
                          'overflow-auto'
                        )}
                        setSelectedLocationOnHover={setSelectedLocationOnHover}
                        selectedMerchantIdFromOutside={selectedLocationOnHover}
                        isNewStoreLocator={true}
                      />
                    </MerchantCardHoverProvider>

                    <LocationBreadCrumb />
                  </div>
                ) : null}

                {!errorLoadingMerchants && !isMobile && !hasResults ? (
                  <StoreLocatorPlaceHolderBanner
                    imageSrc={`assets\\icons\\store-locator\\split_view_empty_icon.svg`}
                    imageHeight={130}
                    imageWidth={130}
                    imageAlt={`Split View Empty Icon`}
                    showMerchantLocationInput={false}
                    showActionButton={false}
                    showBoldText={false}
                    primaryTextString={t(
                      `shared.store_locator_split_view_category_notselected.label`
                    )}
                    dataTestId="not-searched"
                  />
                ) : null}

                {errorLoadingMerchants && !isMobile ? (
                  <StoreLocatorPlaceHolderBanner
                    imageSrc={`assets\\icons\\store-locator\\electric_plug.svg`}
                    imageHeight={130}
                    imageWidth={130}
                    imageAlt={`Split View Empty Icon`}
                    showMerchantLocationInput={false}
                    showActionButton={true}
                    showBoldText={true}
                    primaryTextString={t(`shared.store_locator_failure.label`)}
                    actionButtonText={t(
                      `shared.store_locator_failure.cta_text`
                    )}
                    boldTextString={t(`shared.store_locator_failure.bold_text`)}
                    actionButtonCallback={() => {
                      window.location.reload();
                    }}
                    dataTestId="error-loading-merchants"
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

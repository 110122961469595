import { useState } from 'react';
import { IndustryCard } from '../industry-category/industry-card';
import {
  selectErrorLoadingIndustries,
  useStoreLocatorRouterActions,
} from '@ee-monorepo/store-locator/shared/data-access';
import { storeSelectedIndustryCookie } from '@ee-monorepo/shared/utilities/functions';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { Industry } from '@ee-monorepo/shared/utilities/types';
import { useTranslations } from 'next-intl';
import { colors } from '@snap/colors';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import { selectResultsIndustries } from '@ee-monorepo/store-locator/shared/data-access';
import { useSelector } from 'react-redux';
import { StoreLocatorPlaceHolderBanner } from '../store-locator-placeholder-banner/store-locator-placeholder-banner';
import { useRouter } from 'next/router';
import s from '../main-page-v2/store-locator-v2.module.scss';
import cn from 'classnames';

export const CategoryView = () => {
  const t = useTranslations();
  const router = useRouter();
  const industries = useSelector(selectResultsIndustries);
  const errorLoadingIndustries = useSelector(selectErrorLoadingIndustries);

  const [selectedIndustry, setSelectedIndustry] = useState(
    router?.query?.industry ?? ''
  );
  const { pushIndustry } = useStoreLocatorRouterActions();
  const { tracking } = useTrackingContext();
  const isMobileDevice = useHandHeld();

  const onClick = (e) => {
    setSelectedIndustry(e);
    storeSelectedIndustryCookie(e);
    pushIndustry(e as Industry);

    tracking?.logClickEvent({
      click_type: 'button',
      placement: 'body',
      click_value: t(`shared.industries.${e}.label`),
    });
  };

  const isActive = (industry) =>
    selectedIndustry && industry.industry === selectedIndustry;
  return (
    <div data-testid="category-view">
      <h4
        data-testid="select category"
        className="mb-2"
        style={{ fontSize: isMobileDevice && '16px' }}
      >
        {t('shared.select_category')}
      </h4>

      {errorLoadingIndustries ? (
        <StoreLocatorPlaceHolderBanner
          imageSrc={`assets\\icons\\store-locator\\electric_plug.svg`}
          imageHeight={130}
          imageWidth={130}
          imageAlt={`Split View Empty Icon`}
          showMerchantLocationInput={false}
          showActionButton={true}
          showBoldText={true}
          primaryTextString={t(`shared.store_locator_failure.label`)}
          actionButtonText={t(`shared.store_locator_failure.cta_text`)}
          boldTextString={t(`shared.store_locator_failure.bold_text`)}
          actionButtonCallback={() => {
            window.location.reload();
          }}
        />
      ) : (
        <div
          className={cn(
            s.findStoresScrollable,
            s.maxContentCategory,
            'overflow-auto'
          )}
        >
          <div className="flex flex-wrap">
            {industries &&
              industries.length > 0 &&
              industries.map((industry) =>
                industry.industry !== 'ALL' ? (
                  <div
                    className="min-w-[80px] h-[106px] p-2 w-1/4 lg:w-1/2"
                    key={industry.name}
                    data-testid="list-item"
                  >
                    <IndustryCard
                      classes={
                        isActive(industry)
                          ? 'bg-SnapBlue h-full'
                          : 'bg-SnapSand h-full'
                      }
                      iconColor={
                        isActive(industry)
                          ? colors.SnapWhite
                          : colors.SnapDarkBlue
                      }
                      isMobile={isMobileDevice}
                      fontClass={
                        isActive(industry)
                          ? 'text-SnapWhite uppercase tagSmallFont'
                          : 'text-SnapDarkBlue uppercase tagSmallFont'
                      }
                      industry={industry}
                      onClick={onClick}
                      active={isActive(industry)}
                    />
                  </div>
                ) : null
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryView;

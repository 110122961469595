import { BreadCrumb } from '@ee-monorepo/shared/ui/breadcrumb';
import { useRouter } from 'next/router';

export function LocationBreadCrumb() {
  const router = useRouter();
  return (
    <div data-testid="location-bread-crumb" className="mt-2">
      <BreadCrumb
        url="/#find_stores_that_offer_snap_by_state"
        label={'US'}
        icon
      />
      <BreadCrumb
        url={`/states/${router?.query?.state?.toString().toLowerCase()}`}
        label={`${router?.query.state}`}
        icon
      />
      <BreadCrumb label={`${router?.query.city}`} icon={false} />
    </div>
  );
}
